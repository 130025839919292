import React from "react";

export const Social = () => {
	return (
		<div className="home__social">
			<a
				href="https://www.linkedin.com/in/enricomadeufernandes/"
				className="home__social-icon"
				target="_blank"
				rel="noreferrer"
			>
				<i className="uil uil-linkedin-alt"></i>
			</a>

			<a
				href="https://github.com/enricomadeu"
				className="home__social-icon"
				target="_blank"
				rel="noreferrer"
			>
				<i className="uil uil-github-alt"></i>
			</a>

			<a
				href="https://www.instagram.com/enrico.madeu/?hl=pt-br"
				className="home__social-icon"
				target="_blank"
				rel="noreferrer"
			>
				<i className="uil uil-instagram"></i>
			</a>
		</div>
	);
};
