import "./App.css";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Skills } from "./components/Skills";
import { Qualifications } from "./components/Qualifications";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";

function App() {
	return (
		<>
			<Header />

			<main className="main">
				<Home />
				<About />
				<Skills />
				<Qualifications />
				<Contact />
			</main>
			<Footer />
		</>
	);
}

export default App;
