import React from "react";

export const Frontend = () => {
	return (
		<div className="skills__content">
			<h3 className="skills__title">Frontend</h3>

			<div className="skills__box">
				<div className="skills__group">
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">HTML5</h3>
							<span className="skills__level">Advanced</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">CSS3</h3>
							<span className="skills__level">Intermediate</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Javascript</h3>
							<span className="skills__level">Advanced</span>
						</div>
					</div>
				</div>

				<div className="skills__group">
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">ReactJS</h3>
							<span className="skills__level">Intermediate</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">NextJS</h3>
							<span className="skills__level">Basic</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Angular</h3>
							<span className="skills__level">Intermediate</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
