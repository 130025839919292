import React from "react";
import "./footer.css";

export const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__container container">
				<h1 className="footer__title">Enrico</h1>

				<ul className="footer__list">
					<li>
						<a href="#about" className="footer__link">
							About
						</a>
					</li>
					<li>
						<a href="#skills" className="footer__link">
							Skills
						</a>
					</li>
					<li>
						<a href="#qualifications" className="footer__link">
							Qualifications
						</a>
					</li>
				</ul>

				<div className="footer__social">
					<a
						href="https://www.linkedin.com/in/enricomadeufernandes/"
						className="footer__social-link"
						target="_blank"
						rel="noreferrer"
					>
						<i className="bx bxl-linkedin"></i>
					</a>

					<a
						href="https://github.com/enricomadeu"
						className="footer__social-link"
						target="_blank"
						rel="noreferrer"
					>
						<i className="bx bxl-github"></i>
					</a>

					<a
						href="https://www.instagram.com/enrico.madeu/?hl=pt-br"
						className="footer__social-link"
						target="_blank"
						rel="noreferrer"
					>
						<i className="bx bxl-instagram"></i>
					</a>
				</div>
			</div>
		</footer>
	);
};
