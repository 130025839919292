import React, { useState } from "react";
import "./header.css";

export const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [scrollHeader, setScrollHeader] = useState(false);

	const handleToggleMenu = () => {
		setShowMenu((prev) => !prev);
	};

	window.addEventListener("scroll", () => {
		const scrollValue = document.documentElement.scrollTop;
		if (scrollValue >= 80 && !scrollHeader) {
			setScrollHeader(true);
		} else if (scrollValue < 80 && scrollHeader) {
			setScrollHeader(false);
		}
	});

	return (
		<header className={`header ${scrollHeader ? "scroll_header" : ""}`}>
			<nav className="nav container">
				<a href="index.html" className="nav__logo">
					Enrico
				</a>

				<div className={`nav__menu ${showMenu ? "show-menu" : ""}`}>
					<ul className="nav__list grid">
						<li className="nav__item">
							<a href="#home" className="nav__link">
								<i className="uil uil-estate nav__icon"></i> Home
							</a>
						</li>
						<li className="nav__item">
							<a href="#about" className="nav__link">
								<i className="uil uil-user nav__icon"></i> About
							</a>
						</li>
						<li className="nav__item">
							<a href="#skills" className="nav__link">
								<i className="uil uil-file-alt nav__icon"></i> Skills
							</a>
						</li>
						<li className="nav__item">
							<a href="#qualifications" className="nav__link">
								<i className="uil uil-briefcase-alt nav__icon"></i>{" "}
								Qualifications
							</a>
						</li>
						{/* <li className="nav__item">
							<a href="#portfolio" className="nav__link">
								<i className="uil uil-scenery nav__icon"></i> Portfolio
							</a>
						</li> */}
						<li className="nav__item">
							<a href="#contact" className="nav__link">
								<i className="uil uil-message nav__icon"></i> Contact
							</a>
						</li>
					</ul>

					<i
						className="uil uil-times nav__close"
						onClick={handleToggleMenu}
					></i>
				</div>

				<div className="nav__toggle" onClick={handleToggleMenu}>
					<i className="uil uil-apps"></i>
				</div>
			</nav>
		</header>
	);
};
