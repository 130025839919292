import React from "react";
import Hand from "../../assets/hand.svg";
import Send from "../../assets/send.svg";

export const Data = () => {
	return (
		<div className="home__data">
			<h1 className="home__title">
				Enrico Fernandes&nbsp;
				<img className="home__hand" src={Hand} alt="Yellow Hand" />
			</h1>
			<h3 className="home__subtitle">Full Stack Developer</h3>
			<p className="home__description">
				Graduating in Computer Engineering, based in Santos-SP, passionate about
				programming.
			</p>

			<a href="#contact" className="button button--flex">
				Say Hello&nbsp;
				<img className="button__icon" src={Send} alt="Send" />
			</a>
		</div>
	);
};
