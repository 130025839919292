import React, { useState, useRef } from "react";
import Send from "../../assets/send.svg";
import "./contact.css";
import emailjs from "emailjs-com";
import * as Toast from "@radix-ui/react-toast";

export const Contact = () => {
	const form = useRef();
	const [formValues, setFormValues] = useState({
		name: "",
		email: "",
		project: "",
	});
	// const [name, setName] = useState("");
	// const [email, setEmail] = useState("");
	// const [project, setProject] = useState("");
	const [open, setOpen] = useState(false);
	const timerRef = useRef(0);

	const handleSubmitEmail = () => {
		emailjs
			.sendForm(
				"service_hs27occ",
				"template_retik6k",
				form.current,
				"BYrZ5OxaiC8-ZbBFx"
			)
			.then(
				(result) => {
					console.log(result.text);
					setOpen(false);
					window.clearTimeout(timerRef.current);
					timerRef.current = window.setTimeout(() => {
						setOpen(true);
					}, 100);
				},
				(error) => {
					console.log(error.text);
				}
			);
		setFormValues({
			name: "",
			email: "",
			project: "",
		});
	};

	return (
		<section className="contact section" id="contact">
			<h2 className="section__title">Get in touch</h2>
			<span className="section__subtitle">Contact Me</span>

			<div className="contact__container container grid">
				<div className="contact__content">
					<h3 className="contact__title">Talk to me</h3>

					<div className="contact__info">
						<div className="contact__card">
							<i className="bx bx-mail-send contact__cart-icon"></i>

							<h3 className="contact__card-title">Email</h3>
							<span className="contact__card-data">
								contact@enricomadeu.dev
							</span>

							<a
								href="mailto:contact@enricomadeu.dev.com"
								className="contact__button"
								target="_blank"
								rel="noreferrer"
							>
								Write me{" "}
								<i className="bx bx-right-arrow-alt contact__button-icon"></i>
							</a>
						</div>
						<div className="contact__card">
							<i className="bx bxl-whatsapp contact__cart-icon"></i>

							<h3 className="contact__card-title">Whatsapp</h3>
							<span className="contact__card-data">
								{"+55 (11) 96365-7885"}
							</span>

							<a
								href="https://wa.me/+5511963657885"
								className="contact__button"
								target="_blank"
								rel="noreferrer"
							>
								Write me{" "}
								<i className="bx bx-right-arrow-alt contact__button-icon"></i>
							</a>
						</div>
					</div>
				</div>

				<div className="contact__content">
					<h3 className="contact__title">Write me your proposal</h3>

					<form className="contact__form" ref={form}>
						<div className="contact__form-div">
							<label htmlFor="name" className="contact__form-tag">
								Name
							</label>
							<input
								type="text"
								name="name"
								className="contact__form-input"
								placeholder="Insert your name"
								value={formValues.name}
								onChange={(e) =>
									setFormValues({ ...formValues, name: e.target.value })
								}
							/>
						</div>
						<div className="contact__form-div">
							<label htmlFor="email" className="contact__form-tag">
								Mail
							</label>
							<input
								type="email"
								name="email"
								className="contact__form-input"
								placeholder="Insert your email"
								value={formValues.email}
								onChange={(e) =>
									setFormValues({ ...formValues, email: e.target.value })
								}
							/>
						</div>
						<div className="contact__form-div contact__form-area">
							<label htmlFor="project" className="contact__form-tag">
								Project
							</label>
							<textarea
								name="project"
								cols="30"
								rows="10"
								className="contact__form-input"
								placeholder="Describe your project"
								value={formValues.project}
								onChange={(e) =>
									setFormValues({ ...formValues, project: e.target.value })
								}
							></textarea>
						</div>

						<Toast.Provider swipeDirection="right">
							<button
								type="button"
								onClick={handleSubmitEmail}
								className={`button button--flex ${
									formValues.name === "" ||
									formValues.email === "" ||
									formValues.project === ""
										? "button-disabled"
										: ""
								}`}
								disabled={
									formValues.name === "" ||
									formValues.email === "" ||
									formValues.project === ""
								}
							>
								Send Message
								<img className="button__icon" src={Send} alt="Send" />
							</button>

							<Toast.Root
								className="ToastRoot"
								open={open}
								onOpenChange={setOpen}
							>
								<Toast.Title className="ToastTitle">Email sended!</Toast.Title>
								<Toast.Description asChild>
									<p>Thanks for get in touch, I'll return soon!</p>
								</Toast.Description>
								<Toast.Action
									className="ToastAction"
									asChild
									altText="Goto schedule to undo"
								>
									<button>
										<i className="bx bx-x"></i>
									</button>
								</Toast.Action>
							</Toast.Root>
							<Toast.Viewport className="ToastViewport" />
						</Toast.Provider>
					</form>
				</div>
			</div>
		</section>
	);
};
